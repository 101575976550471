import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Button from "../custom-widgets/button";

const NoLinkCards = (props) => {
  const growClass = props.hasGrowEffect ? "grow" : "";
  const shadowClass = props.hasRiseShadowEffect ? "rise-shadow" : "shadow";

  const desktopClass = props.useMobileView === true ? "d-none d-md-flex" : "";

  return (
    <section id={props.sectionId} className={props.sectionClass}>
      <div className="container">
        {props.title && <h2 className={props.titleClass}>{props.title}</h2>}
        <div className={`row ${desktopClass} ${props.rowColsClass}`}>
          {props.cards.map((card, idx) => (
            <div id={card.id} key={idx} className={`col mb-4 mb-lg-0 ${card.cardClass}`}>
              <div
                className={`card h-100 position-relative border-radius-12 overflow-hidden card-box-shadow ${shadowClass} ${growClass}`}
              >
                {card.image && card.image.imgVariable && (
                  <GatsbyImage
                    image={card.image.imgVariable}
                    alt={card.image.altText}
                    className="card-image-top border-radius-12 border-radius-bottom-0"
                  />
                )}
                <div className="card-body d-flex flex-column align-items-start">
                  {card.title && (
                    <h3 className={`card-title ${card.titleClass}`} dangerouslySetInnerHTML={{ __html: card.title }} />
                  )}
                  {card.text && (
                    <p className={`card-text ${card.textClass}`} dangerouslySetInnerHTML={{ __html: card.text }} />
                  )}
                  {card.anchor && card.anchor.text && (
                    <Link to={card.anchor.url} id={card.anchor.id}>
                      {card.anchor.text}
                    </Link>
                  )}
                  {card.button && card.button.text && <Button {...card.button} />}
                </div>
                {card.url && (
                  <Link to={card.url} className="stretched-link">
                    <span className="sr-only">{card.title}</span>
                  </Link>
                )}
                {card.anchorUrl && (
                  <AnchorLink to={card.anchorUrl} className="stretched-link">
                    <span className="sr-only">{card.title}</span>
                  </AnchorLink>
                )}
              </div>
            </div>
          ))}
        </div>

        {props.button && props.button.text && <Button {...props.button} />}
        {props.link && props.linkText && (
          <div className={props.textAlignClass}>
            <Link id={props.linkId} to={props.link} className={props.linkClass}>
              {props.linkText}
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};
export default NoLinkCards;

NoLinkCards.defaultProps = {
  sectionClass: "bg-light",
  sectionId: "link-cards-section",
  rowColsClass: "row-cols-1 row-cols-md-2 row-cols-lg-3",
  titleClass: "",
  title: "",
  hasGrowEffect: false,
  hasRiseShadowEffect: false,
  button: {},
  textAlignClass: "text-sm-center",
  linkClass: "font-weight-bold",
  link: "",
  linkText: "",
  linkId: "",
  cards: [
    {
      id: "link-card-1",
      cardClass: "col mb-4",
      url: "",
      anchorUrl: "",
      image: {
        imgVariable: null,
        altText: ""
      },
      titleClass: "",
      title: "CARES Program",
      textClass: "",
      text: "Small Business Loan & Paycheck Protection",
      button: {}
    },
    {
      id: "link-card-2",
      cardClass: "col mb-4",
      url: "",
      anchorUrl: "",
      image: {
        imgVariable: null,
        altText: ""
      },
      titleClass: "",
      title: "Coronavirus Mortgage Assistance",
      textClass: "",
      text: "Defer payments for three months",
      button: {}
    },
    {
      id: "link-card-3",
      cardClass: "",
      url: "",
      anchorUrl: "",
      image: {
        imgVariable: null,
        altText: ""
      },
      titleClass: "",
      title: "Refinance your Mortgage",
      textClass: "",
      text: "Take advantage of low rates",
      button: {}
    }
  ]
};
