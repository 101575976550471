import React from "react";

import SimpleLanding from "../../../../components/layouts/simple-landing";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import SEO from "../../../../components/seo/seo";
import NoLinkCards from "../../../../components/card/no-link-cards";

const WholesaleLending = () => {
  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "Wholesale Lending"
    }
  ];

  const title = "Wholesale Mortgage Lending";
  const description =
    "Learn about WaFd Bank's wholesale mortgage lending, how you can work with WaFd Bank to join the program as well as sign into our portal for full access to our Wholesale Lending program.";
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/wholesale-mortgage-lending"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const wholesaleLendingData = {
    sectionClass: "bg-white pb-3",
    rowColsClass: "row-cols-1 row-cols-md-2 row-cols-lg-3",
    cards: [
      {
        id: "wholesale-broker-access-card",
        titleClass: "w-100",
        title: "WaFd Wholesale Broker Access",
        text: "Existing WaFd Brokers can access the wholesale lending tools you need to serve your clients.",
        showIcon: false,
        button: {
          id: "wafd-broker-portal-cta",
          containerClass: "mt-auto w-100",
          type: "anchor",
          showIcon: false,
          externalLink: true,
          target: "blank",
          class: "btn-primary btn-block mt-3",
          text: "WaFd Broker Portal",
          url: "https://wholesale.wafdbank.com"
        }
      },
      {
        id: "become-a-wafd-bank-broker-card",
        titleClass: "w-100",
        title: "Become a WaFd Bank Broker",
        text:
          "Allow our veteran team of mortgage professionals to provide you with the most competitive rates, fastest turn times, and best all around lending experience.",
        showIcon: false,
        button: {
          id: "become-a-partner-cta",
          containerClass: "mt-auto w-100",
          type: "anchor",
          externalLink: true,
          target: "blank",
          showIcon: false,
          class: "btn-primary btn-block mt-3",
          text: "Join Our Network",
          url: "https://wholesale.wafdbank.com/#/content/becomeapartner_303090"
        }
      }
    ]
  };

  return (
    <SimpleLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Wholesale Mortgage Lending</h1>
        <h2>Discover new ways to quickly process loans, strengthen relationships and grow your business</h2>
      </section>
      <NoLinkCards {...wholesaleLendingData} />
    </SimpleLanding>
  );
};

export default WholesaleLending;
